import Vue from 'vue'
import router from './router'

import axios from 'axios'
import JSONbig from 'json-bigint'
import Qs from 'qs'
import { MessageBox } from 'element-ui'

import { setApi, isEle, detectOs } from '@/utils/base.js'
const api = setApi()
Vue.prototype.$msgbox = MessageBox //弹窗
Vue.prototype.$http = axios
    // 2 修改返回的数据结构 -- 响应拦截器
axios.interceptors.request.use((config) => {

        // if (config.method === 'post') {
        //   config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'


        // }

        // 判断用户是否登录
        // 用户用户登录，设置token
        const token = JSON.parse(window.localStorage.getItem('token'))

        // if (token) {
        var isele = isEle()
        var detectos = detectOs()
        var oFrom = ''
        config.headers.authentication = token
        if (!isEle) {
            oFrom = 1
        } else {
            if (detectos == 'mac') {
                oFrom = 3
            } else if (detectos == 'win') {
                oFrom = 2
            } else if (detectos == 'linux') {
                oFrom = 4
            } else {
                oFrom = 1
            }
        }
        config.headers.from = oFrom || 1
            // from = 1 web, 2 windows, 3 mac, 4 linux
            // }

        return config
    }, (error) => {
        return Promise.reject(error)
    })
    // 修改发送数据:发送请求前，处理request的数据
if (api == '') { //打包  
    axios.defaults.baseURL = "https://xapi.beanotes.com"
        //axios.defaults.baseURL = "http://127.0.0.1:6002"
}

axios.defaults.transformRequest = [function(data, headers) {

        // var contentType = "";
        // for (let key in headers){
        //    if (key == "Content-Type"){
        //      contentType = headers[key]
        //    }
        // }
        if (headers['Content-Type'] == "multipart/form-data;") {
            return data
        } else {
            if (data) {
                return Qs.stringify(data)
            } else {
                return data
            }
        }


    }]
    // 修改响应数据:在传递给 then/catch 前，允许修改响应数据
axios.defaults.transformResponse = [function(data) {
    try {
        // data 数据可能不是标准的 JSON 格式字符串，否则会导致 JSONbig.parse(data) 转换失败报错
        if (data) {
            return JSONbig.parse(data)
        } else {
            return data
        }
    } catch (err) {
        return data
            // 无法转换的数据直接原样返回
    }

}]

// 修改响应数据:对响应数据-做些事在发送请求之前做某事
// 2 修改返回的数据结构 -- 响应拦截器
axios.interceptors.response.use(function(response) {
    // var aaa = response.data.data || response.data
    // console.log(
    // if (response.data.status === 203) {
    //     // token过期了去登录
    //     MessageBox.alert(response.data.message, '获取用户信息失败', {
    //         confirmButtonText: '登录',
    //         center: true,
    //         type: 'error',
    //         callback: action => {
    //             if (action === 'confirm') {
    //                 router.push('/login')
    //             }
    //         }
    //     })
    // } else {
    //     // token正常返回数据
    //     return response.data
    // }
    return response.data

}, async function(error) {
    return Promise.reject(error)
})