// 把登录信息储存到本地储存 （读取and删除）
export const setUser = (data) => {
  window.localStorage.setItem('user', JSON.stringify(data))
}

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem('user'))
}

export const removeUser = () => {
  window.localStorage.removeItem('user')
}

// 用户头像储存设置and读取
export const setAvatar = (data) => {
  window.localStorage.setItem('avatar', JSON.stringify(data))
}
export const getAvatar = () => {
  return JSON.parse(window.localStorage.getItem('avatar'))
}
export const removeAvatar = () => {
  window.localStorage.removeItem('avatar')
}
// token储存
export const getToken = () => {
  return JSON.parse(window.localStorage.getItem('token'))
}
export const setToken = (data) => {
  window.localStorage.setItem('token', JSON.stringify(data))
}
export const removeToken = (data) => {
  window.localStorage.removeItem('token')
}
