import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'notes',
    component: () => import('@/views/notes'),
    redirect: '/index',
    children: [
      {
        path: '/index', name: 'notesMain', component: () => import('@/views/notes/home')
      },
      {
        path: '/notesdetail', name: 'notesDetail', component: () => import('@/views/notes/notesdetail')
      }
    ]
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import('@/views/teams'),
    children: [
      {
        path: '/teamsMain', name: 'teamsMain', component: () => import('@/views/teams/home')
      },
      {
        path: '/teamsDetail', name: 'teamsDetail', component: () => import('@/views/teams/teamsdetail'),
        children: [
          {
            path: '/teamsDetail/teamsBook/:tid', name: 'teamsBook', component: () => import('@/views/teams/teamsbook')
          },
          {
            path: '/teamsDetail/teamsColumn/:tid', name: 'teamsColumn', component: () => import('@/views/teams/teamscolumn')
          },
          {
            path: '/teamsDetail/teamsMember/:tid', name: 'teamsMember', component: () => import('@/views/teams/teamsmember')
          },
          {
            path: '/teamsDetail/teamsOrder/:tid', name: 'teamsOrder', component: () => import('@/views/teams/teamsorder')
          }, {
            path: '/teamsDetail/teamsSet/:tid', name: 'teamsSet', component: () => import('@/views/teams/teamsset')
          }
        ]
      }
    ]
  },
  {
    path: '/column',
    name: 'column',
    component: () => import('@/views/column'),
    children: [
      {
        path: '/columnMain', name: 'columnMain', component: () => import('@/views/column/home'),
        children: [
          {
            path: '/columnMain/columnNew', name: 'columnNew', component: () => import('@/views/column/columnnew')
          },
          {
            path: '/columnMain/columnAll', name: 'columnAll', component: () => import('@/views/column/columnall')
          },
          {
            path: '/columnMain/columnMy', name: 'columnMy', component: () => import('@/views/column/columnmy')
          },
          {
            path: '/columnMain/columnOrdered', name: 'columnOrdered', component: () => import('@/views/column/columnordered')
          },
        ]
      },
      {
        path: '/columnMain/specialColumn', name: 'specialColumn', component: () => import('@/views/column/columndetail'),
        children: [
          {
            path: '/s/:Identify', name: 'specialColumnArticle', component: () => import('@/views/column/specialcolumnarticle')
          },
          {
            path: '/columnMain/specialColumn/specialColumnDrafts/:Identify', name: 'specialColumnDrafts', component: () => import('@/views/column/specialcolumndrafts')
          },
          {
            path: '/columnMain/specialColumn/specialColumnAuthor/:Identify', name: 'specialColumnAuthor', component: () => import('@/views/column/specialcolumnauthor')
          }
        ]
      },
      {
        path: '/s/detail/:artid', name: 'specialColumnDetail', component: () => import('@/views/column/specialcolumndetail'),

      },
      {
        path: '/columnMain/specialColumnEdit', name: 'specialColumnEdit', component: () => import('@/views/column/specialcolumnedit'),

      },
      {
        path: '/columnMain/columnInfo', name: 'columnInfo', component: () => import('@/views/column/columninfo'),

      }
    ]
  },
  {
    path: '/book',
    name: 'book',
    component: () => import('@/views/book'),
    children: [
      {
        path: '/bookMain', name: 'bookMain', component: () => import('@/views/book/home'),
        children: [
          {
            path: '/bookMain/bookAll', name: 'bookAll', component: () => import('@/views/book/bookall')
          },
          {
            path: '/bookMain/bookMy', name: 'bookMy', component: () => import('@/views/book/bookmy')
          },
          {
            path: '/bookMain/bookWaitReleas', name: 'bookWaitReleas', component: () => import('@/views/book/bookwaitreleas')
          },
          {
            path: '/bookMain/bookOrdered', name: 'bookOrdered', component: () => import('@/views/book/bookordered')
          }
        ]
      },
      // {
      //   path: '/bookMain/bookIntroduction', name: 'bookIntroduction', component: () => import('@/views/book/bookintroduction')
      // },
      {

        path: '/books/overview/:name', name: 'bookIntroduction', component: () => import('@/views/book/bookintroduction')
      }, 
      {
        path: '/bookMain/bookInfo', name: 'bookInfo', component: () => import('@/views/book/bookinfo')
      },
    ]
  },
  {
    path: '/t/:name', name: 'bookDetail', component: () => import('@/views/book/bookdetail')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/loginin')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register')
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/views/login/reset')
  },
  {
    path: '/foget',
    name: 'foget',
    component: () => import('@/views/login/foget')
  },
  {
    path: '/persion',
    name: 'persion',
    component: () => import('@/views/persion/index.vue'),
    children: [
      {
        path: '/persionBook',
        name: 'persionBook',
        component: () => import('@/views/persion/persionbook/persionbook.vue'),
        children: [
          {
          path: '/persionBookMy',
          name: 'persionBookMy',
          component: () => import('@/views/persion/persionbookmy'),
        },
        {
          path: '/persionBookOrdered',
          name: 'persionBookOrdered',
          component: () => import('@/views/persion/persionbookordered'),
        },
        {
          path: '/persionBookWaitreleas',
          name: 'persionBookWaitreleas',
          component: () => import('@/views/persion/persionbookwaitreleas'),
        }]
      },
      {
        path: '/persionColumn',
        name: 'persionColumn',
        component: () => import('@/views/persion/persioncolumn'),
        children: [
          {
          path: '/persionColumnMy',
          name: 'persionColumnMy',
          component: () => import('@/views/persion/persioncolumnmy'),
        },
        {
          path: '/persionColumnOrdered',
          name: 'persionColumnOrdered',
          component: () => import('@/views/persion/persiondolumnordered'),
        }]
      },
      {
        path: '/persionSet',
        name: 'persionSet',
        component: () => import('@/views/persion/persionset')
      },
      // {
      //   path: '/persionTrans',
      //   name: 'persionTrans',
      //   component: () => import('@/views/persion/persiontrans'),
      //   // children: [
      //   //   {
      //   //   path: '/persionColumnMy',
      //   //   name: 'persionColumnMy',
      //   //   component: () => import('@/views/persion/persioncolumnmy'),
      //   // },
      //   // {
      //   //   path: '/persionColumnOrdered',
      //   //   name: 'persionColumnOrdered',
      //   //   component: () => import('@/views/persion/persiondolumnordered'),
      //   // }]
      // },
      {
        path: '/persionTeam',
        name: 'persionTeam',
        component: () => import('@/views/persion/persionteam'),
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/persion/order/orderlist'),
      },
      {
        path: '/order/withdrawal',
        name: 'orderWithdrawal',
        component: () => import('@/views/persion/order/withdrawal'),
      },
      {
        path: '/order/bandbankcard',
        name: 'orderBandBankcard',
        component: () => import('@/views/persion/order/bandbankcard'),
      }
    ]
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/persion/notice/notice'),
    children:[
        {
          path: '/notice/system',
          name: 'noticeSystem',
          component: () => import('@/views/persion/noticesystem/noticesystem')
        },
        {
          path: '/notice/comment',
          name: 'noticeComment',
          component: () => import('@/views/persion/noticecomment/noticecomment')
        }
      ]
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/views/abouts/question')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/abouts/download.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/abouts/about.vue')
  },
  {
    path: '/pay/:type/:Id',
    name: 'pay',
    component: () => import('@/views/pay')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search'),
    children:[
      {
        path: '/search/searchbook', name: 'searchBook', component: () => import('@/views/book/bookall')
      },
      {
        path: '/search/searchcolumn', name: 'searchColumn', component: () => import('@/views/column/columnall')
      }
    ]
  },

]

const router = new VueRouter({
  routes
})

import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
router.beforeEach((to, from, next) => {
  var token = window.localStorage.getItem('token')
  // if (!token && to.path !== '/login' && to.path !== '/register' && to.path !== '/reset') {
  //   return next('/login')
  // }
  var routerTologo=[
    '/persionColumnMy',//个人中心
    '/persion',
    '/persionBook',
    '/persionBookMy',
    '/persionBookOrdered',
    '/persionBookWaitreleas',
    '/persionColumn',
    '/persionColumnMy',
    '/persionColumnOrdered',
    '/persionSet',
    '/persionTeam',
    '/order',
    '/order/withdrawal',
    '/order/bandbankcard'
    //个人中心end
  ]
  if (!token) {
    if(to.path.indexOf('pay')>0){
      window.localStorage.setItem('goback', JSON.stringify(to.fullPath))
      return next('/login')
    }
    for(var i=0;i<routerTologo.length;i++){
      if(to.path == routerTologo[i]){
        return next('/login')
      }
    }
  }
  next()
})
export default router
