import Vue from 'vue'
import Vuex from 'vuex'
import { getUser, setUser, getAvatar, setAvatar } from '@/utils/auth.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    collapse:false,
    user: getUser(),
    avatar: getAvatar(),
    secid:'',//图书页面，章节id
    tid:'',
    asideNotesChange:'',
    columnOwner:'',//专栏介绍页面是否是作者,
    teamIsmember:''// 团队主页，是否是作者,
  },
  mutations: {
    setCollapse(stt, arg){
      stt.collapse = arg
    },
    setUser (stt, arg) {
      stt.user = arg
      // _thata.$common.setCookie('user',arg,1)
      setUser(arg)
    },
    setAvatar (stt, arg) { //设置头像
      stt.avatar = arg
      setAvatar(arg)
    },
    setSecid(stt,arg){
      stt.secid = arg
    },
    setTid(stt,arg){
      stt.tid = arg
    },
    setAsideNotesChange(stt,arg){
      stt.asideNotesChange = arg
    },
    setColumnOwner(stt,arg){
      stt.columnOwner = arg
    },
    setTeamIsmember(stt,arg){
      stt.teamIsmember = arg
    },

  },
  actions: {
  },
  modules: {
  }
})
