// const api = '/api'   // '网页'
// const api='' //'打包'

export const setApi = () => {

    var api = ""
    var oEnv = process.env.NODE_ENV
    if (oEnv == 'development') { //开发环境s
        api = '/api'
    } else {
        api = '/api'
    }
    return api
}

export const setNewData = (data) => {
    if (data) {
        var key = ''
        var newData = ''
        for (var item in data) {
            key += item + '=' + data[item] + '&'
        }
        if (key) {
            newData = key.slice(0, -1)
            return newData
        }
    }
}
export const setLocItem = (oKey, oData) => {
    oData = JSON.stringify(oData);
    window.localStorage.setItem(oKey, oData);
}
export const getLocItem = oKey => {
    var str = window.localStorage.getItem(oKey);
    return JSON.parse(str)
}
export const removeLocItem = oKey => {
    window.localStorage.removeItem(oKey);
}
export const isEle = oKey => {
    var userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf(" electron/") > -1;
}
export const detectOs = oKey => {
    var userAgent = navigator.userAgent.toLowerCase();
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (userAgent.indexOf("win32") > -1 || userAgent.indexOf("wow32") > -1) {
        return 'win'
    }
    if (userAgent.indexOf("win64") > -1 || userAgent.indexOf("wow64") > -1) {
        return 'win'
    }
    if (isMac) {
        return 'mac'
    }
    if (userAgent.indexOf("Linux") > -1) {
        return 'linux'
    }
    if (userAgent.indexOf(" electron/") > -1) {
        return 'elect'
    }
    return 'other'
}
export const goToPageBrowser = (url, blank) => {
    url = url || ''
    blank = blank || '_blank'
    window.open(url, blank)
}
export const goToPageBrowserele = (url) => {
    url = url || ''
    const { shell } = window.require("electron").remote;
    shell.openExternal(url);
}