import Vue from 'vue'
import App from './App.vue'
import router from './utils/router'
import store from './store'

import '@/assets/font/iconfont.css'// 字体样式
// 引入全局样式控制文件
import '@/assets/css/global.css'
//element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
//element-ui end
import '@/utils/axios.js'
import md5 from 'js-md5' //md5加密
Vue.prototype.$md5 = md5 
Vue.config.productionTip = false

    import mavonEditor from 'mavon-editor' //mavon编辑器
    import 'mavon-editor/dist/css/index.css'
    Vue.use(mavonEditor)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
